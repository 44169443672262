<template>
  <div class="animated fadeIn">
    <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />
    <b-card v-else>
      <template v-slot:header>
        <h5>Laporan Barang Inventaris</h5>
      </template>

      <div class="row">
        <div class="col-lg-4">
          <b-form-group label="Pencarian" label-for="filterInput">
            <b-form-input v-model="filter.text" name="filterTable" id="filterInput" type="search"
              placeholder="Masukan kata kunci..."></b-form-input>
          </b-form-group>
        </div>
        <div class="col-lg-4">
          <b-form-group label-for="filterDate" label="Mulai">
            <b-form-input v-model="filter.dateFrom" name="filterFromDateTarik" id="filterDate" @change="filterDate"
              type="date"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-lg-4">
          <b-form-group label-for="filterDate" label="Hingga">
            <b-form-input v-model="filter.dateTo" name="filterToDateTarik" @change="filterDate"
              type="date"></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <b-form-group label="Pilih Jenis" label-for="filterInput">
            <b-form-select id="filterInput" v-model="filter.jenis" name="filterJenis" @change="">
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>Pilih Jenis</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-lg-4">
          <b-form-group label-for="filterDate" label="Pilih Divisi">
            <b-form-select id="filterInput" v-model="filter.divisi" name="filterJenis" @change="">
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>Pilih Divisi</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-lg-4">
          <b-form-group label-for="filterDate" label="Pilih Karyawan">
            <b-form-select id="filterInput" v-model="filter.karyawan" name="filterJenis" @change="">
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>Pilih Karyawan</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </div>
      </div>
      <b-button variant="pgiBtn" name="resetTableBtn" :disabled="loading == true" @click="onReset()">Reset</b-button>

      <div class="d-flex justify-content-end py-2">
        <b-button variant="pgiBtn">
          <download-excel name="Laporan Inventaris.xls" :data="inventaris" :fields="json_fields">
            <i class="fa fa-download mr-2"></i>
            Excel
          </download-excel>
        </b-button>
      </div>

      <b-table bordered hover show-empty :busy="isBusy" :items="inventaris" :fields="fields" responsive class="mb-3">
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Loading data...</strong>
          </div>
        </template>
        <template v-slot:empty> Tidak ada data jenis barang. </template>
        <template v-slot:cell(kelengkapan)="row">
          <ul class="list-none-wrapper" v-if="row.item.kelengkapan.length > 0">
            <li v-for="kelengkapan in row.item.kelengkapan" v-bind:key="kelengkapan.id">
              <span>
                <i class="fa fa-check" v-if="kelengkapan.isActive == true"></i>
                <i class="fa fa-times" v-if="kelengkapan.isActive == false"></i>
                {{ kelengkapan.name }}
              </span>
            </li>
          </ul>
          <span v-else>-</span>
        </template>
        <template v-slot:cell(status)="row">
          <b-badge variant="success" v-if="row.item.status == true">Suksess</b-badge>
          <b-badge variant="warning" v-else>Menunggu Pembayaran</b-badge>
        </template>
        <template v-slot:cell(action)="row">
          <b-row>
            <b-col cols="1">
              <b-link :to="`${$route.path}/${row.item.noBarang}/detail`">
                <b-button size="sm" class="table-button view" v-b-tooltip.hover title="Detail" name="jenisBarangViewBtn">
                  <i class="fa fa-info"></i>
                </b-button>
              </b-link>
            </b-col>
            <b-col>
              <b-button size="sm" class="table-button view" v-b-tooltip.hover title="Print Barcode" name="stokViewBtn">
                <i class="fa fa-qrcode"></i>
              </b-button>
            </b-col>
          </b-row>
        </template>
      </b-table>

      <div class="pagination-wrapper d-flex align-items-center justify-content-between">
        <span class="ml-2">{{ totalViewText }}</span>
        <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"
          @input="onPageChange"></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
export default {
  name: "laporan-inventaris",
  components: {
    Vue,
  },
  data() {
    return {
      initStatus: 0,
      isBusy: true,
      isLoading: false,
      input: {
        show: 10,
      },
      fields: [
        { key: "no", label: "No", sortable: true },
        { key: "tanggal", label: "Tanggal" },
        { key: "pemegang", label: "Pemegang" },
        { key: "jabatan", label: "Jabatan" },
        { key: "noBarang", label: "No Barang" },
        { key: "namaBarang", label: "Nama Barang" },
        { key: "imei", label: "Imei" },
        { key: "detailbarang", label: "Detail Barang" },
        { key: "action", label: "Aksi" },
      ],
      json_fields: {
        No: "no",
        Tanggal: "tanggal",
        Pemegang: "pemegang",
        "No Barang": "noBarang",
        "Nama Barang": "namaBarang",
        Imei: "imei",
        "Detail Barang": "detailBarang",
      },
      filter: {
        jenis: null,
        divisi: null,
        karyawan: null,
        text: null,
        dateFrom: null,
        dateTo: null,
      },

      inventaris: [],

      currentPage: 1,
      perPage: null,
      rows: null,
      totalViewText: "",
      page: "",
      size: "",
      from: "",
      limit: "",
      query: "",
    };
  },
  methods: {
    //----------------- Fungsi Inisialisasi -----------------

    init() {
      this.initStatus = 0;
      this.query = "?page=" + 1;
      let getInventaris = this.$axios
        .get("api/admin/laporan/inventaris")
        .then((response) => {
          let datas = response.data.data;

          let i = 1;
          for (var data of datas.data) {
            this.inventaris.push({
              no: i,
              tanggal: data.tanggal,
              pemegang:
                data.karyawan != null ? data.karyawan.nama_karyawan : "-",
              jabatan:
                data.karyawan != null
                  ? data.karyawan.jabatan.nama_jabatan
                  : "-",
              noBarang: data.nomer_barang,
              namaBarang:
                data.barang_tipe == null ? "-" : data.barang_tipe.tipe,
              imei: data.imei,
              detailbarang: data.detail_barang,
            });
            i++;
          }

          this.perPage = datas.per_page;
          this.size = this.perPage;
          this.from = datas.from;
          this.limit = datas.to;

          this.updateTotalItem(datas.total);
          let msg = this.$helper.getFlash();
          this.$helper.toastSucc(this, msg);
        });

      Promise.all([getInventaris])
        .then(() => {
          this.initStatus = 1;
          let msg = this.$helper.getFlash();
          this.$helper.toastSucc(this, msg);
          this.toggleBusy();
        })
        .catch((error) => {
          console.log(error);
          this.initStatus = -1;
        });
    },

    //-----------------  Fungsi Ambil Data Jenis Barang -----------------

    getInventaris() {
      this.toggleBusy();
      this.inventaris = [];

      if (this.filter.dateFrom != null)
        this.query =
          this.query +
          "&startDate=" +
          this.filter.dateFrom +
          "&endDate=" +
          this.filter.dateTo;
      this.$axios
        .get("api/admin/laporan/inventaris" + this.query)
        .then((response) => {
          let datas = response.data.data;

          let i = datas.from;
          for (var data of datas.data) {
            this.inventaris.push({
              no: i,
              tanggal: data.tanggal,
              pemegang:
                data.karyawan != null ? data.karyawan.nama_karyawan : "-",
              jabatan:
                data.karyawan != null
                  ? data.karyawan.jabatan.nama_jabatan
                  : "-",
              noBarang: data.nomer_barang,
              namaBarang:
                data.barang_tipe == null ? "-" : data.barang_tipe.tipe,
              imei: data.imei,
              detailbarang: data.detail_barang,
            });
            i++;
          }

          this.perPage = datas.per_page;
          this.size = this.perPage;
          this.from = datas.from;
          this.limit = datas.to;
          this.isLoading = false;

          this.toggleBusy();
          this.updateTotalItem(datas.total);
          let msg = this.$helper.getFlash();
          this.$helper.toastSucc(this, msg);
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response.data.reason);
          this.$helper.parseError(this, error);
          this.toggleBusy();
          this.isLoading = false;
        });
    },

    //----------------- Fungsi Pagination, Sorting, dan Filtering -----------------

    filterDate() {
      if (this.filter.dateFrom != null && this.filter.dateTo != null) {
        this.getInventaris();
      }
    },

    onPageChange() {
      if (this.isLoading == false) {
        this.page = this.currentPage;
        this.query = "?page=" + this.page;
        this.getInventaris();
      }
    },

    onReset() {
      (this.filter = {
        jenis: null,
        divisi: null,
        karyawan: null,
        text: null,
        dateFrom: null,
        dateTo: null,
      }),
        (this.page = 1);
      this.query = "?page=" + this.page;
      this.currentPage = 1;
      this.loading = true;
      this.getInventaris();
    },

    //----------------- Fungsi Pindah Halaman dan Fungsi Lain -----------------

    toggleBusy() {
      if (this.isBusy == true) this.isBusy = false;
      else this.isBusy = true;
    },

    updateTotalItem(total) {
      this.perPage = this.size;
      this.rows = total;
      if (this.limit > this.rows) limit = this.rows;
      this.totalViewText =
        "Menampilkan " +
        this.from +
        " - " +
        this.limit +
        " dari " +
        this.rows +
        " entri";
    },
  },

  created() {
    this.init();
  },
};
</script>
